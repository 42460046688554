import React, { useState } from "react";
import "../Styles/nft-debit-card.css";
import Footer from "../components/footer";
export default function NftDebitCard() {
	// Array of boolean states, one for each col-md-3 section
	const [showBenefitsContentArray, setShowBenefitsContentArray] = useState([
		true,
		true,
		true,
		true,
	]);

	// Function to toggle the visibility of a specific section
	const toggleBenefitsContent = (index) => {
		const newShowBenefitsContentArray = [...showBenefitsContentArray];
		newShowBenefitsContentArray[index] = !newShowBenefitsContentArray[index];
		setShowBenefitsContentArray(newShowBenefitsContentArray);
	};

	return (
		<>
			<header className="roadmap-header">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="header-text" style={{ textAlign: "left" }}>
								<h2>
									{" "}
									Fuxion <span className="header-span">Pay</span>
								</h2>
								<p className="fuxion-pay-p">
								Spend your crypto profits with our NO KYC virtual crypto debit card (Visa and Mastercard) with a maximum limit of up to $1,000 USD. <a href="https://checkout.fuxionlabs.ai/prepaid/rauwfdms27xvwkqw9m4665v9tlvtn6vz70267kuwaqg97k0qw65oc8imx7yg" target="_blank" style={{color:"#04F280", textDecoration:"none", fontSize:"1.2rem",fontWeight:"bolder",fontFamily:"Orbitron"}}>Order here.</a>
									<br /> <br />
								Or choose our exclusively branded cards, valid in 170 countries, with a maximum spend of up to $150,000 per month with a physical debit card (coming soon).
								</p>
							</div>
							<div className="div-button-about">
								<a href="https://checkout.fuxionlabs.ai/prepaid/rauwfdms27xvwkqw9m4665v9tlvtn6vz70267kuwaqg97k0qw65oc8imx7yg" target="_blank">
								<button  type="button" className="btn btn-success lading-about-button">Order Now</button>
								</a>
								
							</div>
						</div>
						<div className="col-md-6">
							<div className="img-header-nft" style={{ marginTop: "0%" }}>
								<img
									className="header-one-img"
									src="/assets/images/Group 102.png"
									alt=""
								/>
								{/* <img
									className="header-one-img image-post"
									src="/assets/images/Frame 47.png"
									alt=""
								/> */}
							</div>
						</div>
					</div>
				</div>
			</header>

			<section className="physical-card">
				<div className="container">
					<div className="physical-card-text">
						<h3>Physical & VIRTUAL Card</h3>
						<h2>Benefits</h2>
					</div>
					<div className="row benefits-section">
							<div className="col-md-3">
								<div className="benefits-cards">
									<div className="benefits-content" >
										<div className="benefits-content-header">
											<h1>Convenience & Global Acceptance</h1>
										</div>
										<div className="benefits-content-p">
											<p>
												Spend your crypto with ease by buying online using a
												Fuxion virtual card or our physical card to withdraw
												from ATMs, and tapping to pay for groceries like a
												normal Mastercard or Visa card. Accessibility extends to
												170+ countries.
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="benefits-cards">
									<div className="benefits-content"								>
										<div className="benefits-content-header">
											<h1>Spend Crypto like cash</h1>
										</div>
										<div className="benefits-content-p">
											<p>
												Crypto debit cards let you ditch the exchange and
												transfer hassle free. Use your crypto directly at
												millions of stores worldwide that accept debit cards.
												Save on fees, conversion rates, and enjoy the
												convenience of direct spending from your wallet.{" "}
											</p>
										</div>
									</div>
								</div>
							</div>

						
							<div className="col-md-3">
								<div className="benefits-cards">
									<div className="benefits-content">
										<div className="benefits-content-header">
											<h1>Unleash your Crypto</h1>
										</div>
										<div className="benefits-content-p">
											<p>
												Load your debit card with a wide range of tokens and
												stablecoins from different networks. This provides
												flexibility to cater to different crypto options, giving
												you ultimate control over your digital assets{" "}
											</p>
										</div>
									</div> 
								</div>
							</div>


							<div className="col-md-3">
								<div className="benefits-cards">
									<div className= "benefits-content">
										<div className="benefits-content-header">
											<h1>Flex your Spending Power</h1>
										</div>
										<div className="benefits-content-p">
											<p>
												Enjoy a whopping $150k monthly spending limit using our
												Fuxion metal / plastic physical card or Go Virtual using
												our Fuxion Virtual card with a $10,000 monthly limit on
												balance and transactions for USA VISA or $1,000 for
												Global Mastercard.{" "}
											</p>
										</div>
									</div>
									
								</div>
							</div>
				
					</div>
				</div>
			</section>
			<Footer/>
		</>
	);
}
