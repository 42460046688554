import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../Styles/header.css"; // Import your custom CSS file

export default function Header() {
	return (
		<div className="container">
			<div className="row">
				<Navbar expand="lg" className="custom-navbar">
					<div className="col-lg-4 col-md-12">
						<Navbar.Brand as={Link} to="/">
							<img
								loading="lazy"
								alt="logo"
								src="/assets/images/logo-pic.png"
								className="imgLogo"
							/>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="101"
								height="18"
								viewBox="0 0 101 18"
								fill="none"
							>
								<path
									d="M84.7512 18C83.9559 18 83.3112 17.3553 83.3112 16.56V2.21997C83.3112 1.39154 83.9828 0.719971 84.8112 0.719971H85.9002C86.3433 0.719971 86.7638 0.915889 87.0488 1.25518L95.0386 10.7669C95.9391 11.8389 97.6872 11.2022 97.6872 9.80214V2.17197C97.6872 1.37005 98.3373 0.719971 99.1392 0.719971C99.9411 0.719971 100.591 1.37005 100.591 2.17197V16.5C100.591 17.3284 99.9196 18 99.0912 18H98.002C97.559 18 97.1386 17.8041 96.8536 17.465L88.8396 7.92773C87.939 6.85598 86.1912 7.49282 86.1912 8.89271V16.56C86.1912 17.3553 85.5465 18 84.7512 18Z"
									fill="white"
								/>
								<path
									d="M66.3882 18C65.7962 18 65.2522 17.856 64.7562 17.568C64.2602 17.28 63.8682 16.888 63.5802 16.392C63.2922 15.896 63.1482 15.352 63.1482 14.76V3.95997C63.1482 3.36797 63.2922 2.82397 63.5802 2.32797C63.8682 1.83197 64.2602 1.43997 64.7562 1.15197C65.2522 0.863971 65.7962 0.719971 66.3882 0.719971H77.1882C77.7802 0.719971 78.3162 0.863971 78.7962 1.15197C79.2922 1.43997 79.6842 1.83197 79.9722 2.32797C80.2762 2.82397 80.4282 3.36797 80.4282 3.95997V14.76C80.4282 15.352 80.2762 15.896 79.9722 16.392C79.6842 16.888 79.2922 17.28 78.7962 17.568C78.3162 17.856 77.7802 18 77.1882 18H66.3882ZM66.4602 15.096H77.0682C77.1962 15.096 77.3002 15.056 77.3802 14.976C77.4762 14.896 77.5242 14.792 77.5242 14.664V4.05597C77.5242 3.92797 77.4762 3.82397 77.3802 3.74397C77.3002 3.66397 77.1962 3.62397 77.0682 3.62397H66.4602C66.3482 3.62397 66.2442 3.66397 66.1482 3.74397C66.0682 3.82397 66.0282 3.92797 66.0282 4.05597V14.664C66.0282 14.792 66.0682 14.896 66.1482 14.976C66.2442 15.056 66.3482 15.096 66.4602 15.096Z"
									fill="white"
								/>
								<path
									d="M59.0594 18C58.2707 18 57.6314 17.3606 57.6314 16.572V2.14797C57.6314 1.35931 58.2707 0.719971 59.0594 0.719971C59.848 0.719971 60.4874 1.35931 60.4874 2.14797V16.572C60.4874 17.3606 59.848 18 59.0594 18Z"
									fill="white"
								/>
								<path
									d="M38.3597 18C38.0875 18 37.8668 17.7793 37.8668 17.5071C37.8668 17.3914 37.9075 17.2794 37.9818 17.1906L43.4637 10.644C44.0858 9.90101 44.0858 8.81894 43.4637 8.07597L37.9818 1.5293C37.9075 1.44058 37.8668 1.32857 37.8668 1.21286C37.8668 0.940645 38.0875 0.719971 38.3597 0.719971H40.1546C40.7433 0.719971 41.302 0.979297 41.682 1.42886L44.928 5.26892C45.7281 6.21548 47.1876 6.21387 47.9857 5.26555L51.2114 1.43223C51.5914 0.980651 52.1515 0.719971 52.7417 0.719971H54.5343C54.8063 0.719971 55.0268 0.940483 55.0268 1.2125C55.0268 1.32842 54.9859 1.44062 54.9114 1.52937L49.4137 8.07132C48.7879 8.81609 48.7887 9.90318 49.4157 10.647L54.9068 17.1616C54.9843 17.2535 55.0268 17.3699 55.0268 17.4902C55.0268 17.7717 54.7986 18 54.517 18H52.739C52.1504 18 51.5916 17.7406 51.2116 17.2911L47.9657 13.451C47.1656 12.5045 45.706 12.5061 44.908 13.4544L41.6822 17.2877C41.3022 17.7393 40.7421 18 40.1519 18H38.3597Z"
									fill="white"
								/>
								<path
									d="M21.1838 18C20.5918 18 20.0478 17.856 19.5518 17.568C19.0558 17.28 18.6638 16.888 18.3758 16.392C18.0878 15.896 17.9438 15.352 17.9438 14.76V2.15997C17.9438 1.36468 18.5885 0.719971 19.3838 0.719971C20.1791 0.719971 20.8238 1.36468 20.8238 2.15997V14.664C20.8238 14.792 20.8638 14.896 20.9438 14.976C21.0398 15.056 21.1438 15.096 21.2558 15.096H31.8638C31.9918 15.096 32.0958 15.056 32.1758 14.976C32.2718 14.896 32.3198 14.792 32.3198 14.664V2.17197C32.3198 1.37005 32.9699 0.719971 33.7718 0.719971C34.5737 0.719971 35.2238 1.37005 35.2238 2.17197V14.76C35.2238 15.352 35.0718 15.896 34.7678 16.392C34.4798 16.888 34.0878 17.28 33.5918 17.568C33.1118 17.856 32.5758 18 31.9838 18H21.1838Z"
									fill="white"
								/>
								<path
									d="M1.90806 18C1.10614 18 0.456062 17.3499 0.456062 16.548V2.71997C0.456062 1.6154 1.35149 0.719971 2.45606 0.719971H14.8681C15.67 0.719971 16.3201 1.37005 16.3201 2.17197C16.3201 2.97389 15.67 3.62397 14.8681 3.62397H5.36006C4.25549 3.62397 3.36006 4.5194 3.36006 5.62397V5.89597C3.36006 7.00054 4.25549 7.89597 5.36006 7.89597H12.3361C13.1446 7.89597 13.8001 8.55143 13.8001 9.35997C13.8001 10.1685 13.1446 10.824 12.3361 10.824H5.36006C4.25549 10.824 3.36006 11.7194 3.36006 12.824V16.548C3.36006 17.3499 2.70998 18 1.90806 18Z"
									fill="white"
								/>
							</svg>
						</Navbar.Brand>
					</div>
					<div className="col-lg-8 col-md-12 align-self-end">
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse
							id="basic-navbar-nav"
							className="justify-content-end"
						>
							<Nav className="mr-auto">
								<Nav.Link as={Link} to="/about">
									About us
								</Nav.Link>
								<NavDropdown title="Products" id="navbarDropdown">
									<NavDropdown.Item as={Link} to="nft-debit-card">
										Fuxion Pay
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="web3-escrow">
										WEB3 escrow
									</NavDropdown.Item>
								</NavDropdown>
								{/* <NavDropdown title="Service" id="navbarDropdown">
                  <NavDropdown.Item as={Link} to="/deadShot-service">DeadShot Service</NavDropdown.Item>
                </NavDropdown> */}

								<Nav.Link as={Link} to="/fuxionhub">
									FuxionHub
								</Nav.Link>
								<Nav.Link
									target="_blank"
									href="/assets/pdf/Fuxion Labs Pitch Deck V1_July2024.pdf"
								>
									Pitch Deck
								</Nav.Link>
								<Nav.Link as={Link} to="/roadmap">
									Roadmap
								</Nav.Link>
								<Nav.Link as={Link} to="/Contact-us">
									Contact us
								</Nav.Link>
							</Nav>
							<Nav>
								<Nav.Link
									as={Link}
									to="https://www.dextools.io/app/en/ether/pair-explorer/0x2e1c1eb6727a4b96a549306e0f963792300f8b3c?t=1712963066162"
									target="_blank"
								>
									{/* <Nav.Link as={Link}> */}
									<button
										type="button"
										className="btn btn-outline-success my-2 my-sm-0 lading-button"
									>
										Get $FUXE
									</button>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>
		</div>
	);
}
