import React from "react";

import "../Styles/footer.css";

export default function footer() {
	return (
		<>
			<footer className="footer_two">
				<div className="container">
					<div className="footer-want">
						<div className="row">
							<div className="col-8">
								<h2>
									For more enquiries
									<br />
									Join our TG community
								</h2>
							</div>
							<div className="col-4">
								<a href="Contact-us">
									<button
										type="button"
										className="btn btn-success f-lading-button"
										style={{ background: "black", color: "#25ff00" }}
									>
										Join Now
									</button>
								</a>
							</div>
						</div>
					</div>

                    <div className="footer-menu">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="footer-menu-heading">
                                        <h2>Products</h2>
                                        <h3>
                                            <a href="#">FuxionHub</a>
                                        </h3>
                                        <h3>
                                            <a href="web3-escrow">
                                            Web3 Escrow
                                            </a>
                                        </h3>
                                        <h3>
                                            <a href="#">
                                                Crypto Debit Card
                                            </a>
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="footer-menu-heading">
                                        <h2>Documentation</h2>

                                        <h3>
                                            <a href="#">
                                            Pitch Deck
                                            </a>
                                        </h3>
                                        <h3>
                                            <a href="#">Whitepaper</a>
                                        </h3>
                                        <h3>
                                            <a href="#">
                                            FuxionHub Guide
                                            </a>
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="footer-menu-heading">
                                        <h2>Subscribe for Updates</h2>
                                        <h3>
                                            Subscribe for our monthly wrap-up & Alpha updates!
                                        </h3>
                                        <div className="row">
                                            <div className="col-9">
                                                <input type="text" placeholder="email" className="input-text" />
                                            </div>
                                            <div className="col-3">
                                                <button className="input-submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
			</div>
			</footer>
		</>
	);
}
