import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Dashboard from "./Dashboard/dashboard";
import Wallet from "./Dashboard/wallet";
import Stock from "./Dashboard/stock";
import Profile from "./Dashboard/profile";
import Cryto from "./Dashboard/cryto";
import Forex from "./Dashboard/forex";
import Sentimentalanalysis from "./Dashboard/sentimental-analysis";
import Technicalanalysis from "./Dashboard/technicalanalysis";
import Login from "./Dashboard/login";
import Header from "./components/header";
import LandingPage from "./pages/landing";
import About from "./pages/about";
import Deadshot from "./pages/deadshot";
import Roadmap from "./pages/roadmap";
import Staking from "./pages/Staking";
import DeadshotService from "./pages/deadshot_service";
import Nftdebitcard from "./pages/nft-debit-card";
import Web3escrow from "./pages/web3-escrow";
import Getlynx from "./pages/getlynx";
import ContactUS from "./pages/contact-us";
import Escrow from "./pages/ecrrow-dapp";
import TokenUtility from "./pages/token-utility";

function App() {
  const location = useLocation();
  const isHiddenRoute = location.pathname === "/dashboard--11100" || location.pathname === "/Wallet--11100" || location.pathname === "/profile--11100" || location.pathname === "/stock--11100" || location.pathname === "/Cryto--11100" || location.pathname === "/forex--11100" || location.pathname === "/technical-analysis--11100" || location.pathname === "/sentimental-analysis--11100" || location.pathname === "/login--11100";
  
  return (
    <div id="app">
      {!isHiddenRoute && <Header />}
      <Routes>
        <Route path="/dashboard--11100" element={<Dashboard />} />
        <Route path="/profile--11100" element={<Profile />} />
        <Route path="/wallet--11100" element={<Wallet />} />
        <Route path="/stock--11100" element={<Stock />} />
        <Route path="/Cryto--11100" element={<Cryto />} />
        <Route path="/forex--11100" element={<Forex />} />
        <Route path="/sentimental-analysis--11100" element={<Sentimentalanalysis />} />
        <Route path="/technical-analysis--11100" element={<Technicalanalysis />} />
        <Route path="/login--11100" element={<Login />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/fuxionhub" element={<Deadshot />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/deadshot-service" element={<DeadshotService />} />
        <Route path="/nft-debit-card" element={<Nftdebitcard />} />
        <Route path="/web3-escrow" element={<Web3escrow />} />
        <Route path="/getlynx" element={<Getlynx />} />
        <Route path="/Contact-us" element={<ContactUS />} />
        <Route path="/escrow" element={<Escrow />} />
        <Route path="/token-utility" element={<TokenUtility />} />
      </Routes>
    </div>
  );
}

export default App;
