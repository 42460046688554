import React from "react";
import Footer from "../components/footer";
import "../Styles/nft-debit-card.css";
export default function web3escrow() {
	return (
		<>
			<header className="roadmap-header">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="header-text" style={{ textAlign: "left" ,marginTop:'15%' }}>
								<h2>
									{" "}
									Web3 <span className="header-span">Escrow</span>
								</h2>
								<p>
									An OTC service built into our ecosystem - enabling you to
									carry <br /> out safe, smart contract regulated P2P transactions for
									tokens <br /> at pre-agreed terms.
								</p>
							</div>
							{/* <div className="nft-debit-button">
                    <button type="button" className="btn btn-success lading-button">Add Image</button>
                </div> */}
						</div>
						<div className="col-md-6">
							<div className="img-header-one response-img-header-one" style={{ marginTop: "0%" }}>
								<div className="web3-escrow">
								<img
									className="web3-escro-img header-one-img"
									src="/assets/images/Vector (1) 2.png"
									alt=""
								/>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</header>
			<section className="physical-card">
				<div className="container">
					<div className="web-physical-card-text physical-card-text">
						<h3>ESCROW MODEL</h3>
						<h2>Benefits</h2>
					</div>
				</div>
			</section>
			<section className="core-principal">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="core-principal-div">
								<img
									src="/assets/images/Frame 49.png"
									className="core-principal-uimg"
									alt=""
									srcset=""
								/>
								<h2>Security</h2>
								<p>
									Our Smart contract automates the release of funds, removing
									the risk of human error or intentional manipulation.
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div">
								<img
									src="/assets/images/Frame 50.png"
									className="core-principal-uimg"
									alt=""
									srcset=""
								/>
								<h2>Transparency</h2>
								<p>
									All transaction details are stored on the blockchain,
									providing a permanent and verifiable record for everyone.
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div">
								<img
									src="/assets/images/Frame 51.png"
									className="core-principal-uimg"
									alt=""
									srcset=""
								/>
								<h2>Decentralization</h2>
								<p>
									There's no single entity controlling the process, reducing
									reliance on trusted third-parties and fostering trust in the
									system
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div">
								<img
									src="/assets/images/Frame 52.png"
									className="core-principal-uimg"
									alt=""
									srcset=""
								/>
								<h2>Efficiency</h2>
								<p>
									Our Smart contract streamlines the escrow process, saving time
									and resources compared to traditional methods.{" "}
								</p>
							</div>
						</div>
					</div>
					<section className="ecrow-model">
						<div className="ecrow-model-heading">
							<h3>ESOROW Model</h3>
							<h2>How it Works</h2>
						</div>
						<div className="ecrow-box-data">
							<div className="row">
						

								<div className="col-md-4">
									<div className="ecrow-box-content">
										<div className="ecrow-box-content-data">
											<h2>01</h2>
											<h3>
											Initiate Transaction
											</h3>
											<p>
												Buyer and seller agree on terms and initiate the transaction through a Web3 escrow platform.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="ecrow-box-content">
										<div className="ecrow-box-content-data">
											<h2>02</h2>
											<h3>
											Funds Desposited
											</h3>
											<p>
												Buyer desposits the agreed-upon amount in cryptocurrency or digital assests into a secure smart contract wallet.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="ecrow-box-content">
										<div className="ecrow-box-content-data">
											<h2>03</h2>
											<h3>
											Initiate Transaction
											</h3>
											<p>
												Seller deilvers the agreed-upon service.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-2"></div>
								<div className="col-md-4">
									<div className="ecrow-box-content">
										<div className="ecrow-box-content-data">
											<h2>04</h2>
											<h3>
											Buyer Verifies
											</h3>
											<p>
												Buyer Confirms receopt and Satisfaction with the delivered goods or service.
											</p>
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div className="ecrow-box-content">
										<div className="ecrow-box-content-data">
											<h2>05</h2>
											<h3>
											Funds Released
											</h3>
											<p>
												Upon buyer confirmation, the smart contract automatically releases the funds or assests to the seller.
											</p>
										</div>
									</div>
								</div>

								<div className="col-md-2"></div>
							</div>
						</div>
					</section>

					<section className="safe-secure">
						<div className="row">
							<div className="col-md-3">
								<div className="safe-secure-heading">
									<h2>
										Safe.
										<br />
										Secure.
										<br />
										Trustless
									</h2>
								</div>
							</div>

					
						
							<div className="col-md-9">
								<div className="safe-secure-box">
									<div className="safe-secure-data">
										<div className="col-12">
											<div className="form-heading">
												Title
											</div>
											<div className="form-heading-input">
												<input type="text" />
											</div>
										</div>

										<div className="col-12">
											<div className="form-heading">
											Reciever
											</div>
											<div className="form-heading-input">
												<input type="text" />
											</div>
										</div>

										<div className="col-12">
											<div className="form-heading">
												Token
											</div>
											<div className="form-heading-input">
												<input type="text" />
											</div>
										</div>

										<div className="row form-heading-row">
											<div className="col-6">
												<div className="form-heading">
													Total in tokens
												</div>
												<div className="form-heading-input">
													<input type="text" />
												</div>
											</div>
											<div className="col-6">
												<div className="form-heading">
													Total in USD
												</div>
												<div className="form-heading-input">
													<input type="text" />
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="text-center">
												<button type="button" className="create-contract" >Coming Soon</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</section>
			<Footer/>
		</>
	);
}
