import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/dashboard.css"; // Import your custom CSS file
import { LineChart } from '@mui/x-charts';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import Dashboard_header from "../components/dashboard-headher";
import DashBoardHeader from "../components/SideBar";

// BasicLineChart component
import {
    LinePlot,
    lineElementClasses,
    markElementClasses,
  } from '@mui/x-charts/LineChart';
  
const pData1 = [2, 5.5, 2, 8.5, 1.5, 5];
const pData2 = [0, 10, 2, 5, 2, 10];
const pData3 = [5, 4.9, 4, 3.5, 1.5, 3];
const xData = [1, 2, 3, 5, 8, 10];
  
function BasicLineChart() {
    return (
      <ChartContainer
        width={'800'}
        height={400}
        series={[
            { type: 'line', data: pData1, color: '#C0E6BA'},
            { type: 'line', data: pData2, color:'#04F280'},
            { type: 'line', data: pData3,color:"yellow" },
        ]}
        xAxis={[{ scaleType: 'point', data: xData, showLabels: false }]} // Hide x-axis labels
        yAxis={[{ showLabels: false }]} // Hide y-axis labels
        sx={{
        //   [`& .${lineElementClasses.root}`]: [
        //     { stroke: '#FF5733', strokeWidth: 2 }, // Change color of pData1
        //     { stroke: '#33FF57', strokeWidth: 2 }, // Change color of pData2
        //     { stroke: '#3357FF', strokeWidth: 2 }, // Change color of pData3
        //   ],
          [`& .${markElementClasses.root}`]: {
            display: 'none', // Hide dots on the line
          },
        }}
        disableAxisListener
      >
        <LinePlot />
      </ChartContainer>
    );
}

export default function Dashboard() {
	return (
		<>
            <div className="container-fluid">
                <div className="row">
                     <DashBoardHeader/>

                        <div className="col-md-10">
                                 <Dashboard_header/>
                                 
                            <section>
                                <div className="row">
                                    <div className="col-md-9">
                                        <p className="fux-dash-lab"><span className="fux-dash-labimg"><img src="/assets/images/Fuxion Profile Photo 1.svg" alt="" srcset="" /></span>FuxionLabs | $FUXE</p>

                                       {/* Current-price button */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="fux-dash-button-d">
                                                <div className="row">
                                                    <div className="col-8">
                                                    <p className="fux-data-p">Current Price</p>
                                                        <h3>$3,00,00</h3>
                                                    </div>
                                                    <div className="col-4">
                                                         <div className="up align-middle">
                                                            <p><span className="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                            <path d="M3.04644 0.523723C3.28571 0.247446 3.71429 0.247446 3.95356 0.523723L6.53733 3.50721C6.87386 3.89579 6.59782 4.5 6.08377 4.5H0.916225C0.402174 4.5 0.126143 3.89579 0.462669 3.50721L3.04644 0.523723Z" fill="#04F280"/>
                                                            </svg></span> 0.01%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="fux-dash-button-d">
                                                <p className="fux-data-p">Current Price</p>
                                                <h3>$3,52,0000</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="fux-dash-button-d">
                                                <p className="fux-data-p">Current Price</p>
                                                <h3>$36,000</h3>
                                                </div>
                                            </div>
                                        </div>
                                         {/* End Current-price button */}

                                        {/* technial-analysis */}
                                        <div className="technial-analysis">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="technial-analysis-p">Technical Analysis</p>
                                                    <div className="technial-analysis-img" id="chart-container">
                                             <BasicLineChart />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <p className="technial-analysis-p">Prediction Model</p>
                                                        <div className="pre-model-box">
                                                            <div className="pred-model-data-active">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <p>Average Prediction</p>
                                                                        <h2>$3.32</h2>
                                                                    </div>
                                                                    <div className="col-4">
                                                                    <div class="up-moodel-pre"><p><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                    <path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"/>
                                                                    </svg></span> 0.01%</p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pred-model-data">
                                                            <div className="row">
                                                                    <div className="col-8">
                                                                        <p>RF Prediction</p>
                                                                        <h2>$3.313</h2>
                                                                    </div>
                                                                    <div className="col-4">
                                                                    <div class="up-moodel-pre-black"><p><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                    <path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"/>
                                                                    </svg></span> 0.01%</p></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="pred-model-data">
                                                            <div className="row">
                                                                    <div className="col-8">
                                                                        <p>GB Prediction</p>
                                                                        <h2>$3.35</h2>
                                                                    </div>
                                                                    <div className="col-4">
                                                                    <div class="up-moodel-pre-black"><p><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                    <path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"/>
                                                                    </svg></span> 0.01%</p></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="pred-model-data">
                                                            <div className="row">
                                                                    <div className="col-8">
                                                                        <p>LR Prediction</p>
                                                                        <h2>$3.317</h2>
                                                                    </div>
                                                                    <div className="col-4">
                                                                    <div class="up-moodel-pre-black"><p><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                    <path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"/>
                                                                    </svg></span> 0.01%</p></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                          {/* End technial-analysis */}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="perfome-dashboard">
                                            <h2 className="perfome-dashboard-h2">Performance</h2>
                                            <div className="perfome-dashboard-circel">
                                                <svg className="perfome-dashboard-circel-box" width="100%" height="200" viewBox="0 0 200 200">
                                                    <circle cx="100" cy="100" r="90" stroke="#454444" stroke-width="20" fill="none"></circle>
                                                    <circle class="progress" cx="100" cy="100" r="90" stroke="#9DED69" stroke-width="20" fill="none" stroke-dasharray="565.48" stroke-dashoffset="141.37" stroke-linecap="round"></circle>
                                                    <text class="circle-box-text" x="50%" y="50%" text-anchor="middle" fill="white" font-family="Jeko-Medium" font-size="40" font-weight="bolder" dy=".3em">75</text>
                                                </svg>
                                            </div>

                                                <div className="perfor-dashboard-space">
                                                    <div className="perfome-dashboard-box">
                                                            <div className="row">
                                                                <div className="col-9">
                                                                    <p className="perfome-dashboard-box-p">Audit Score 
                                                                        <span className="score-svg">
                                                                            <img src="/assets/images/Frame 6.svg" alt="" srcset="" />
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                        <div className="col-3">
                                                            <svg className="perfome-dashboard-circel-box" width="100%" height="40" viewBox="0 0 200 200">
                                                                <circle cx="100" cy="100" r="90" stroke="#454444" stroke-width="20" fill="none"></circle>

                                                                <circle class="progress" cx="100" cy="100" r="90" stroke="#9DED69" stroke-width="20" fill="none" stroke-dasharray="600.48" stroke-dashoffset="141.37" stroke-linecap="round"></circle>
                                                                
                                                                <text class="circle-box-text" x="50%" y="50%" text-anchor="middle" fill="white" font-family="Jeko-Medium" font-size="40" font-weight="bolder" dy=".3em">85</text>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="perfome-dashboard-box">
                                                        <div className="row">
                                                            <div className="col-9">
                                                                <p className="perfome-dashboard-box-p">Token Security   <span className="score-svg">
                                                                        <img src="/assets/images/Frame 6.svg" alt="" srcset="" />
                                                                    </span></p>
                                                                
                                                            </div>
                                                            <div className="col-3">
                                                        <svg className="perfome-dashboard-circel-box" width="100%" height="40" viewBox="0 0 200 200">
                                                            <circle cx="100" cy="100" r="90" stroke="#454444" stroke-width="20" fill="none"></circle>
                                                            
                                                                <circle class="progress" cx="100" cy="100" r="90" stroke="#9DED69" stroke-width="20" fill="none" stroke-dasharray="565.48" stroke-dashoffset="141.37" stroke-linecap="round"></circle>
                                                                
                                                                <text class="circle-box-text" x="50%" y="50%" text-anchor="middle" fill="white" font-family="Jeko-Medium" font-size="40" font-weight="bolder" dy=".3em">75</text>
                                                        </svg>
                                                    </div>
                                                        </div>
                                                </div>
                                                </div>
                                                <div className="perfome-dashboard-box">
                                                        <div className="row">
                                                            <div className="col-9">
                                                                <p className="perfome-dashboard-box-p">Trust Score   <span className="score-svg">
                                                                        <img src="/assets/images/Frame 6.svg" alt="" srcset="" />
                                                                    </span></p>
                                                            </div>
                                                        <div className="col-3">
                                                            <svg className="perfome-dashboard-circel-box" width="100%" height="40" viewBox="0 0 200 200">
                                                            <circle cx="100" cy="100" r="90" stroke="#454444" stroke-width="20" fill="none"></circle>

                                                                <circle class="progress" cx="100" cy="100" r="90" stroke="#9DED69" stroke-width="20" fill="none" stroke-dasharray="565.48" stroke-dashoffset="141.37" stroke-linecap="round"></circle>
                                                                
                                                                <text class="circle-box-text" x="50%" y="50%" text-anchor="middle" fill="white" font-family="Jeko-Medium" font-size="40" font-weight="bolder" dy=".3em">75</text>
                                                           </svg>
                                                    </div>
                                                        </div>
                                                </div>
                                                <div className="perfome-dashboard-box">
                                                        <div className="row">
                                                            <div className="col-9">
                                                                <p className="perfome-dashboard-box-p">Contract Security   <span className="score-svg">
                                                                        <img src="/assets/images/Frame 6.svg" alt="" srcset="" />
                                                                    </span></p>
                                                            </div>
                                                    <div className="col-3">
                                                            <svg className="perfome-dashboard-circel-box" width="100%" height="40" viewBox="0 0 200 200">
                                                            <circle cx="100" cy="100" r="90" stroke="#454444" stroke-width="20" fill="none"></circle>

                                                            <circle class="progress" cx="100" cy="100" r="90" stroke="#9DED69" stroke-width="20" fill="none" stroke-dasharray="565.48" stroke-dashoffset="141.37" stroke-linecap="round"></circle>
                                                            
                                                            <text class="circle-box-text" x="50%" y="50%" text-anchor="middle" fill="white" font-family="Jeko-Medium" font-size="40" font-weight="bolder" dy=".3em">75</text>
                                                        </svg>
                                                    </div>
                                                        </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="model-pre-ps">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="technial-analysis-p">Twitter News</p>
                                            </div> 
                                            <div className="col-md-6">
                                                 <p className="technial-analysis-p">Other News</p>
                                            </div> 
                                            <div className="col-md-6">
                                                <div className="twitter-news">

                                                </div>
                                            </div>    
                                            <div className="col-md-2">
                                                <div className="other-news">

                                                </div>
                                            </div>
                                              <div className="col-md-2">
                                                <div className="other-news">
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="other-news">
                                                    
                                                </div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </section>
                        </div>  
                    </div>
                </div>
        </>
    )}