import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/dashboard.css"; // Import your custom CSS file
import { LineChart } from '@mui/x-charts';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import Dashboard_header from "../components/dashboard-headher";

export default function Cryto() {
    return (
        <>
          <div className="container-fluid">
                <div className="row">
                        <div className="col-md-2">
                            <div className="dashboard-header">
                                {/* Sidebar data */}
                                <div className="dash-logo">
                                     <img className="mx-auto d-block" src="/assets/images/Fuxion Logo.png" alt="" srcset="" />
                                </div>
                                <div className="dash-menu-wa">
                                    <div className="menu-data">
                                    <Nav.Link as={Link} to="/dashboard">
                                        <p className="p-dash-board">
                                            <span className="p-dash-board-span"><img  src="/assets/images/Group 2.svg" alt="" srcset="" /></span> Dashboard
                                        </p>
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/Wallet">
                                        <p className="p-dash-board">   
                                            <span className="p-dash-board-span"><img  src="/assets/images/solar_wallet-bold.svg" alt="" srcset="" /></span> Wallet
                                        
                                        </p>
                                    </Nav.Link>
                                    </div>
                                </div>
                                <div className="das-menu-footer">
                                    <div className="menu-data">
                                        <Nav.Link as={Link} to="/profile">
                                                <p className="p-dash-board">
                                                    <span className="p-dash-board-span"><img  src="/assets/images/iconamoon_profile-fill.svg" alt="" srcset="" /></span> Profile
                                                </p>
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/Wallet">
                                        <p className="p-dash-board">
                                                    <span className="p-dash-board-span"><img  src="/assets/images/Logout.svg" alt="" srcset="" /></span> Log out
                                                </p>
                                        </Nav.Link>
                                        </div>
                                    </div>
                            </div>
                        </div> 
                        <div className="col-md-10">
                                   <Dashboard_header/>
                                {/* logo-price */}
                                          
                                {/* End logo-price */}
                        </div>
                    </div>
                </div>
        </>
    )}