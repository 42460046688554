import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../Styles/landing.css";
export default function landing() {
	return (
		<>
			<header className="lading-header">
				<div className="container">
					<div className="lading-details">
						<h2>
							unified gaTEWAY TO A CONNECTED{" "}
							<span className="h-lading-p">WEB3 FUTURE</span>
						</h2>
						<p>
							Fuxion Labs empowers users to explore Web3 by developing a
							platform that provides access to different DApp services.
						</p>
						{/* <div className="lading-buttons">
                  <div className="row">
                    <div className="col-6">
                    <button type="button" className="btn btn-success lading-button" style={{background:'black',color:'white'}}>DeadShot</button>
                    </div>
                    <div className="col-6">
                    <button type="button" className="btn btn-success lading-button">DeadShot</button>
                    </div>
                </div>
                </div> */}
					</div>
				</div>
			</header>

			<section className="company">
				<div className="lading-company">
					<div className="container">
						<div className="row">
							<div className="support-by">
								<p>SUPPORTED BY</p>
							</div>

							<div className="col-3">
								<img
									src="/assets/images/microsoft-startups 1.png"
									className="mx-auto d-block support-by-image"
									alt=""
									srcset=""
									
								/>
							</div>
							<div className="col-3">
								<img
									src="/assets/images/google-cloud-for-startups 1.png"
									className="mx-auto d-block support-by-image"
									alt=""
	 								srcset=""
								/>
							</div>
							<div className="col-3">
								<img
									src="/assets/images/amazon-web-services-partner-network-logo-2FDD89EBEC-seeklogo 1.png"
									className="mx-auto d-block support-by-image"
									alt=""
									srcset=""
								/>
							</div>
							<div className="col-3">
								<img
									src="/assets/images/Group 281.png"
									alt=""
									className="mx-auto d-block support-by-image"
									srcset=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="cards-det">
				<div className="container card-desc">
					<div className="row">
						<div className="col-6">
							<div className="img-header-two">
								<img
									className="header-one-img"
									src="/assets/images/main.png"
									alt=""
								/>
								<img
									className="header-one-ladning"
									src="/assets/images/iPhone 13 Pro Graphite Mockup label.png"
									alt=""
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="card-details">
								<h2>Fuxion Hub</h2>
								<p>
									Elevate your crypto DEX trading strategies with our arsenal of
									high-performance tools. <br />
									<br />
									Key features include: buy/sell orders, advanced limit order
									protocols, anti-bot protection, transaction simulation, <br />
									AI smart contract audits and access to other services.
								</p>
								<a href="fuxionhub">
									<button
										type="button"
										href="FuxionHub"
										className="btn btn-success lading-button card-details-lading-button"
									>
										FuxionHub
									</button>
								</a>
							</div>
						</div>

						<div className="col-6">
							<div className="card-details">
								<h2>Crypto Debit Card</h2>
								<p>
									Spend your crypto anywhere with a virtual or physical
									Visa/Mastercard debit card, valid in 170 countries and
									offering ATM withdrawals and up to a 50% service tax discount.
								</p>
								<a href="nft-debit-card">
									<button
										type="button"
										className="btn btn-success lading-button card-details-lading-button"
									>
										Learn more
									</button>
								</a>
							</div>
						</div>

						<div className="col-6">
							<div className="img-header-card img-header-one">
								<img
									className="header-one-img"
									src="/assets/images/Group 102.png"
									alt=""
								/>
							</div>
						</div>

						<div className="col-6">
							<div className="img-web3-escrow img-header-one">
								<img
									className="header-one-img"
									src="/assets/images/Group 36.png"
									alt=""
								/>
							</div>
						</div>

						<div className="col-6">
							<div className="card-details">
								<h2>Web3 Escrow</h2>
								<p>
									Secure way to handle online transactions using blockchain
									technology.
									<br />
									<br /> It acts like a neutral third party, holding funds or
									assets until both buyer and seller fulfill their agreed-upon
									conditions.
								</p>
								<a href="web3-escrow">
									<button
										type="button"
										className="btn btn-success lading-button card-details-lading-button"
									>
										Web3 Escrow{" "}
									</button>
								</a>
							</div>
						</div>

						<div className="col-6">
							<div className="card-details">
								<h2>Infuxe ( Software as a Service )</h2>
								<p>
									Integrate our in-house technology with your project (s) to
									achieve faster time-to-market feature deployment, save
									development time and costs, and add an additional revenue
									stream to your project.
								</p>
								<a href="Contact-us">
									<button
										type="button"
										className="btn btn-success lading-button card-details-lading-button"
									>
										Contact us
									</button>
								</a>
							</div>
						</div>

						<div className="col-6">
							<div className="img-infuxe img-header-one">
								<img
									className="header-one-img"
									src="/assets/images/Integrate 2@4x 1.png"
									alt=""
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="img-collaborate img-header-one">
								<img
									className="header-one-img"
									src="/assets/images/Collaborate 2@4x 1.png"
									alt=""
								/>
							</div>
						</div>

						<div className="col-6">
							<div className="card-details">
								<h2>Collaborate</h2>
								<p>
									Let's collaborate! We can offer you several exciting
									opportunities: release a co-branded NFT & crypto debit card
									for your project, integrate your dapp with Fuxionhub to boost
									its exposure.
									<br />
									For KOLs, we can arrange a partnership with a 50% share of the
									1% platform tax.
								</p>
								<a href="Contact-us">
									<button
										type="button"
										className="btn btn-success lading-button card-details-lading-button"
									>
										Contact us
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="token">
				<div className="container">
					<div className="lading-token">
						<div className="row">
							<div className="col-5">
								<img
									src="/assets/images/Token PNG 1.png"
									className="mx-auto d-block lading-token-img"
									alt=""
									srcset=""
								/>
							</div>
							<div className="col-7">
								<h2>$FUXE Token</h2>
								<p>
									$FUXE is our native utility token. It powers the ecosystem,
									enabling premium access to our products and other top tier
									features.
								</p>
								<button
									type="button"
									className="btn btn-success lading-button"
									style={{ background: "black", color: "white" }}
								>
									DeadShot
								</button>
								<button
									type="button"
									className="btn btn-success lading-button"
									style={{ margin: "2%" }}
								>
									DeadShot
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="Team">
				<div className="lading-team">
					<div className="container">
						<div className="row">
							<div className="lading-team-h2">Meet Our Team</div>
							<div className="col-1"></div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2259 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Cyrus Garcia</h3>
									</div>
									<div className="lading-team-type">
										<p>Founder/CEO</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
												<a href="https://twitter.com/pizzamakerph" target="_blank">
													<img
														src="/assets/images/x.png"
														className="mx-auto d-block lading-team-twiiter"
														alt=""
													/>
												</a>
												<a href="https://www.linkedin.com/in/mrcmg/" target="_blank">
													<img
														src="/assets/images/linkedin.png"
														className="mx-auto d-block our-team-img"
														alt=""
													/>
												</a>
											</div>
											
										</div>
									</div>
								</div>
							</div>

							 <div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2261 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcSet=""
									/>
									<div className="lading-team-name">
										<h3>Patrick Andras</h3>
									</div>
									<div className="lading-team-type">
										<p>Founder/CMO</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
											<a href="https://twitter.com/woofBIGDAWG" target="_Blank">
												<img
													src="/assets/images/x.png"
													className="mx-auto d-block lading-team-twiiter"
													alt=""
												/>
											</a>
											<a href="https://www.linkedin.com/in/bigdawg-crypto/" target="_Blank">
												<img
													src="/assets/images/linkedin.png"
													style={{ width: "100%" }}
													className="mx-auto d-block our-team-img"
													alt=""
												/>
											</a>
											&nbsp;
											<a href="https://www.youtube.com/c/BigDawgCryptoPodcast?cbrd=1" target="_Blank">
												<img
													src="/assets/images/cib_youtube.png"
													style={{ width: "100%" }}
													className="mx-auto d-block our-team-img"
													alt=""
												/>
											</a>
										</div>
										</div>
									</div>
								</div>
							</div>
					
							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2264 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>K. Casparov</h3>
									</div>
									<div className="lading-team-type">
										<p>Director of PM</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
											<a href="https://twitter.com/dionysus_xv" target="_Blank">
												<img
													src="/assets/images/x.png"
													className="mx-auto d-block lading-team-twiiter"
													alt=""
												/>
											</a>
										</div>
									</div>
									</div>
								</div>
							</div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2265 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Misha</h3>
									</div>
									<div className="lading-team-type">
										<p>Lead Engineer</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
										<div className="leading-team-social">
											<a href="https://www.linkedin.com/in/ggwm/" target="_Blank">
												<img
													src="/assets/images/linkedin.png"
													className="mx-auto d-block our-team-img"
													alt=""
												
												/>
											</a>
											&nbsp;
											<a href="hhttps://github.com/ggwmwgg" target="_blank">
												<img
													src="/assets/images/icomoon-free_github.png"
													
													className="mx-auto d-block our-team-img"
													alt=""
												/>
											</a>
										</div>
									</div>
									</div>
								</div>
							</div>
							</div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2267 1.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Usama</h3>
									</div>
									<div className="lading-team-type">
										<p>Blockchain Dev</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
											<a href="https://www.linkedin.com/in/usamaaaa/" target="_blank">
												<img
													src="/assets/images/linkedin.png"
													className="mx-auto d-block our-team-img"
													alt=""
												/>
											</a>&nbsp;
											<a href="https://github.com/usamalatif" target="_blank">
												<img
													src="/assets/images/icomoon-free_github.png"
													className="mx-auto d-block our-team-img"
													alt=""
												/>
											</a>
										</div>
										</div>
									</div>
								</div> 
							</div>

							<div className="col-1"></div>
							<div className="col-1"></div>
							

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2263 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Goef</h3>
									</div>
									<div className="lading-team-type">
										<p>Senior ML & AI Architect</p>
									</div>
								</div>
							</div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/Group 13.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>A. Daniel</h3>
									</div>
									<div className="lading-team-type">
										<p>Product Designer</p>
									</div> 
									{/* <div className="lading-social" style={{ width: "25%" }}>
										<div className="leading-team-social">
											<a href="https://x.com/alex_yyz">
												<img
													src="/assets/images/x.png"
													className="mx-auto d-block"
													alt=""
												/>
											</a>
											<a href="https://www.linkedin.com/in/mrcmg/">
												<img
													src="/assets/images/linkedin.png"
													style={{ width: "100%" }}
													className="mx-auto d-block"
													alt=""
												/>
											</a>
										</div>
									</div> */}
							 </div>
							</div> 

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2260 1.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Alex</h3>
									</div>
									<div className="lading-team-type">
										<p>Partnership Manager</p>
									</div>
									<div className="lading-social">
										<div className="leading-team-social">
											<div className="lading-team-images">
											<a href="https://x.com/alex_yyz" target="_blank">
												<img
													src="/assets/images/x.png"
													className="mx-auto d-block lading-team-twiiter"
													alt=""
												/>
											</a>
										</div>
									</div>
									</div>
								</div>
							</div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2266 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Sen</h3>
									</div>
									<div className="lading-team-type">
										<p>Graphic Designer</p>
									</div>
								</div>
							</div>

							<div className="col-2">
								<div className="lading-team-img">
									<img
										src="/assets/images/IMG_2262 2.png"
										className="mx-auto d-block team-lading-img"
										alt=""
										srcset=""
									/>
									<div className="lading-team-name">
										<h3>Dixie</h3>
									</div>
									<div className="lading-team-type">
										<p> Content Writer</p>
									</div>
								
								</div>
							</div> 
						</div>
					</div>
				</div>
			</section>

			<section className="fuxionist">
				<div className="container">
					<h2 className="lading_title">Contract Address</h2>
					<p className="fuxionist_p  pp-text">
						0x3FCA2CD116121deCd03043fbaBA39F60651DE903
					</p>
				</div>
			</section>

			<section className="partners">
				<h2>Partners</h2>

				<div className="row gx-4">
					<div className="col">
						<a href="https://microgpt.io/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/MicroGPT.png"
								alt=""
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div> 

					<div className="col">
						<a href="https://www.paal.ai/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/paal_logo.png"
								alt=""
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div>

					<div className="col">
						<a href="#" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/0xscans 1.png"
								alt="dd"
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div>

					<div className="col">
						<a href="https://selfcrypto.io/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/Self.png"
								alt=""
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div>

					<div className="col">
						<a href="https://www.starbot.pro/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/Starbot.png"
								className="mx-auto d-block img-fluid"
								alt=""
							/>
						</a>
					</div>		

					<div className="col">
						<a href="#" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/ToadSwap.png"
								className="mx-auto d-block img-fluid"
								alt=""
							/>
						</a>
					</div>		

					<div className="col">
						<a href="https://cryptocart.cc/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/Vault 1.png"
								alt=""
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div>

					<div className="col">
						<a href="https://unidexai.xyz/" target="_blank" rel="noreferrer">
							<img
								src="/assets/images/UnidexAi.png"
								alt=""
								className="mx-auto d-block img-fluid"
							/>
						</a>
					</div>
					
				</div>
			</section>

			<section className="fuxionist">
				<div className="container">
					<h2 className="lading_title">Become a Fuxioneer</h2>
					<p className="fuxionist_p">
						Join our community to learn more about what we’re building. Get the
						latest news and insights in our groups below.
					</p>
					<div className="fuxinist-contact">
						<div className="row">

							<div className="col-2"></div>
							<div className="col-2">
								<a
									href="https://x.com/Fuxionlabs?t=zDvSplqvpO6zdPvlwITbbA&s=09"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/assets/images/Frame 22.png"
										className="mx-auto d-block contect-links"
										alt=""
										srcset=""
									/>
								</a>
							</div>
							<div className="col-2">
								<a
									href="https://www.linkedin.com/company/fuxionlabsai/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/assets/images/Frame 22 2.png"
										className="mx-auto d-block contect-links"
										alt=""
										srcset=""
									/>
								</a>
							</div>
							<div className="col-2">
								<a
									href="https://medium.com/fuxion-labs"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/assets/images/Frame 23.png"
										className="mx-auto d-block contect-links"
										alt=""
										srcset=""
									/>
								</a>
							</div>
							<div className="col-2">
								<a
									href="https://t.me/fuxionlabsai"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/assets/images/Frame 24.png"
										alt=""
										className="mx-auto d-block contect-links"
										srcset=""
									/>
								</a>
							</div>
						</div>
						
					</div>
				</div>
			</section>
			<Footer/>
		</>
	);
}
