import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/login.css"; 
// BasicLineChart component
export default function Login() {
	return (
		<>
            <section className="login-page">
                    <div className="login-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="login-logo">
                                        <img src="/assets/images/Fuxion Profile Photo 1.png" alt="" srcset="" />
                                    </div>
                                    <div className="logo-p">
                                        <p className="logo-p-text">
                                            Launch your AI Companion
                                        </p>
                                    </div>
                                    <div className="logo-input">
                                        <input type="text" name="" value="Email / Username" id="" className="logo-input-text"/>
                                    </div>
                                    <div className="logo-input">
                                        <button className="logo-input-button">
                                            Launch AI
                                        </button>
                                    </div>
                                    <div className="or-div">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="line"></div>
                                            </div>
                                            <div className="col-2">
                                                <p className="or-p"> or </p>
                                            </div>
                                            <div className="col-5">
                                                 <div className="line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="connect-your-wallet">
                                        <button className="connect-your-wallet-button">
                                            Connect your wallet
                                        </button>
                                    </div>
                                    <div className="support-by-login">
                                        <p>
                                            SUPPORTED BY <b>MICROSOFT FOR STARTUPS</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )};