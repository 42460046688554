import "../Styles/escrow.css"; // Import your custom CSS file
import Footer from "../components/footer";
export default function Escrow() {
	return (
		<>
			<div className="container">
				<header className="header">
					<h1 className="header-h1">WEB3 ESCROW</h1>
					<p className="header-p">Safe. Secure. Transparent.</p>
				</header>

				<section className="form">
					<div className="form-input-group">
						<input type="text" className="form-input" value="Title" />
						<div className="line-green"></div>
					</div>
					<div className="form-input-group">
						<input type="text" className="form-input" value="Reciever" />
						<div className="line-green"></div>
					</div>
					<div className="form-input-group">
						<div className="col-12">
							<input type="text" className="form-input" value="Token" />
							<div className="line-green"></div>
						</div>
					</div>

					<div className="form-input-group">
						<div className="row">
							<div className="col-6">
								<input type="text" className="form-input" value="Token" />
								<div className="line-green"></div>
							</div>
							<div className="col-6">
								<input
									type="text"
									className="form-input"
									value="Total in USD"
								/>
								<div className="line-green"></div>
							</div>
						</div>
					</div>

					{/* <div className="form-button">
						<button className="form-button-e">Create Contract</button>
					</div> */}
					<div className="form-button">
						<button className="form-button-d">Create Contract</button>
					</div>
				</section>
			</div>
			<Footer/>
		</>
	);
}
