import React from "react";
import "../Styles/contact-us.css";
import Footer from "../components/footer";
import Header from "../components/header";
export default function ContactUS() {
	return (
		<>
			<div className="container">
				<section className="contact-us">
					<div className="row">
						<div className="col-md-6">
							<form>
								<div class="form-row">
									<div class="form-group contact-us-spacing col-md-12">
										<label for="inputEmail4">Email</label>
										<input
											type="email"
											className="form-control"
											id="inputEmail4"
											placeholder="Email"
										/>
									</div>
									<div className="row">
										<div class="form-group contact-us-spacing col-md-6">
											<label for="fname">First Name</label>
											<input
												type="text"
												className="form-control"
												id="fname"
												placeholder="First Name"
											/>
										</div>
										<div class="form-group contact-us-spacing col-md-6">
											<label for="lname">Last Name</label>
											<input
												type="text"
												className="form-control"
												id="lname"
												placeholder="Last Name"
											/>
										</div>
										<div class="form-group contact-us-spacing">
											<label for="exampleFormControlTextarea1">Message</label>
											<textarea
												class="form-control"
												id="exampleFormControlTextarea1"
												rows="3"
											></textarea>
										</div>
										<div className="col-4">
											<button
												type="submit"
												class="btn btn-primary mb-2 card-details-lading-button"
											>
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="col-md-6 contact-us-details">
							<h2>Contact Us</h2>
							<p>
								For questions, technical assistance, or collaboration
								opportunities please contact us via any of these ways provided.
								We will always respond as soon as we can.
							</p>
							<p>
								<a href="https://twitter.com/Fuxionlabs">Twitter</a>
							</p>
							<p>
								<a href="https://linkedin.com/company/fuxionlabsai">Linkedin</a>
							</p>
							<p>
								Email: <b>inquiry@fuxionlabs.ai</b>
							</p>
						</div>
					</div>
				</section>
			</div>
			<Footer/>
		</>
	);
}
