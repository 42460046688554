import React from "react";
import '../Styles/staking.css'
import Footer from "../components/footer";
export default function Staking() { 
  return (
    <>
         <header className='roadmap-header'>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="header-text">
                <h2><span className='header-span'>Roadmap</span>  <br /> Development </h2>
                <p>
                Stake your $LYNX for up to 25% APR. Tokens are locked for fixed periods of time, each period comes with it's own APR. More time, more rewards.
                </p>
                <button type="button" className="btn btn-success lading-button">DeadShot</button>
                </div>
                
              </div>
              <div className="col-6">
              </div>
            </div>
          </div>
     </header>
    
    <section className="stake-dash">
        <div className="container">
            <div className="row">
            
                <div className="total_staked">
                    <div className="row">
                        <div className="col-8">
                            <div className="total_staked_graph">
                                <img src="/assets/images/Vector 51.png" alt="" />
                            </div>
                        </div>
                        <div className="col-3">
                           <div className="total-stakes-data">
                            <h3> Staked</h3>
                            <h2>227,177</h2>
                            <h4>$LYNX</h4>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choose_arp">
                <div className="col-12">
                    <div className="choose-data">
                        <select name="" id="" className="choose-data-select">
                            <option value="">Choose an ARP</option>
                        </select>
                    </div>
                 
                </div>
                <div className="choose-data-container">
                <div className="row">
                    <div className="col-4">
                        <div className="choose-data-box">
                                <h5>ARP</h5>
                                <h1>17.99%</h1>
                                <h5>2 week Look</h5>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="choose-data-box">
                                <h5>ARP</h5>
                                <h1>17.99%</h1>
                                <h5>2 week Look</h5>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="choose-data-box">
                                <h5>ARP</h5>
                                <h1>17.99%</h1>
                                <h5>2 week Look</h5>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
};