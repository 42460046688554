import React, { useEffect, useState } from "react";
import "../Styles/roadmap.css";
import Footer from "../components/footer";

export default function Roadmap() {
	useEffect(() => {
		const equalizeHeights = () => {
			const rows = document.querySelectorAll(".row");
			rows.forEach((row) => {
				let maxHeight = 0;
				row.querySelectorAll(".roadmap-div").forEach((div) => {
					const height = div.getBoundingClientRect().height;
					if (height > maxHeight) {
						maxHeight = height;
					}
				});
				row.querySelectorAll(".roadmap-div").forEach((div) => {
					div.style.minHeight = `${maxHeight}px`;
				});
			});
		};

		equalizeHeights();
		window.addEventListener("resize", equalizeHeights);

		return () => window.removeEventListener("resize", equalizeHeights);
	}, []);

	return (
		<>
			<header className="roadmap-header">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="roadmap-header-text">
								<h2>
									{" "}
									Development <br />
									<span className="header-span">Roadmap</span>
								</h2>
							</div>
						</div>
						<div className="col-md-6">
							<p className="roadmap-header-text-p">
								This development roadmap outlines the creation of a secure and a
								user-friendly Web3 platform. The initial phase focuses on core
								functionalities, followed by an expansion phase with
								partnerships and mobile access. Finally, the platform will
								integrate advanced technologies for a continuously evolving
								experience.
							</p>
						</div>
					</div>
				</div>
			</header>
			<section
				className="daas-line"
				style={{ minHeight: "3rem", marginTop: "10%" }}
			>
				<div className="container">
					<div className="daas-lines">
						<div className="row">
							<div className="col-4">
								<img
									src="/assets/images/Ellipse 36.png"
									className="dass-img-one"
									alt=""
									srcset=""
								/>
							</div>
							<div className="col-4">
								<img
									src="/assets/images/Ellipse 37.png"
									className="dass-img-two"
									alt=""
									srcset=""
								/>
							</div>
							<div className="col-4">
								<img
									src="/assets/images/Ellipse 31.png"
									className="dass-img-three "
									alt=""
									srcset=""
								/>
							</div>
						</div>
					
					</div>
				</div>
			</section>
			<div className="roadmap-spaces"></div>
			<section className="road-map-section">
				<div className="container">
					<div className="row">
						<div className="col-md-4 p-u">
							<div className="roadmap-data-header">
								<h3>
									Build & <br />
									Collaborate
								</h3>
								<p>Phase 1</p>
							</div>
							<div className="roadmap-div">
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Web3 Escrow
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Buy/Sell Trades
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Limit Order Bot
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Limit Order Protocol
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Wallet Management
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Custom Buttons
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Quick buy Button partners
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Anti Bot Protections
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									TXN Simulation
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Deposit/Withdrawal
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Web3 dApp Partners
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									DEX Quote Aggregator
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Broadcast Feature
								</p>
							</div>
						</div>
						{/* Repeat similar structure for other columns */}

						<div className="col-md-4 p-u">
							<div className="roadmap-data-header">
								<h3>
									Expansion & <br />
									Ecosystem Growth
								</h3>
								<p>Phase 2</p>
							</div>
							<div className="roadmap-div">
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Referral Program
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Push notification advertisement
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									AI SC Audit (Partners)
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Virtual Debit Card
								</p>
								
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Crypto card Partnership
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									NFT Plastic/Metal card
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Mobile app Design
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Build ML/AI model
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Expansion to SOL & Base
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Trading app 2FA
								</p>
								
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Wallet Analysis
								</p>
							
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									$FUXE Subscription Model
								</p>
							</div>
						</div>

						<div className="col-md-4 p-u">
							<div className="roadmap-data-header">
								<h3>
									Tech Advancement & <br />
									Continous Improvement
								</h3>
								<p>Phase 3</p>
							</div>
							<div className="roadmap-div">
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button-2.png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									KOL Partner Feature
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Web2 Service Integration
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									ML/AI Feature build
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Market Sentiment View
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									Mobile App Build (SAAS)
								</p>
								<p>
									<span>
										{" "}
										<img
											src="/assets/images/roadmap button (1).png"
											className="roadmap-div-img"
											alt=""
											srcset=""
										/>
									</span>{" "}
									API Subscription
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>
		</>
	);
}
