import React from "react";
import "../Styles/deadshot.css";
import Footer from "../components/footer";
export default function DeadShot() {
	return (
		<>
			<header className="about-header">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2 className="lading-h2">
								<span className="h-lading-p"> FuxionHub V1</span> <br />
								is now live!
							</h2>
							<p className="lading-p">
								Unleash the full potential of Web3. Your one-stop hub for
								seamless interactions between projects and services.
							</p>
							{/* <div className="row"> 
                    <div className="col-6">
                    <button type="button" className="btn btn-success lading-button" style={{background:'black',color:'white'}}>DeadShot</button>
                    </div>
                    <div className="col-6">
                    <button type="button" className="btn btn-success lading-button">DeadShot</button>
                    </div>
                </div> */}
						</div>
						<div className="col-md-6">
						<div className="img-header-two" style={{marginTop:'0%'}}>
								<img
									className="header-one-img"
									src="/assets/images/main.png"
									alt=""
								/>
								<img
									className="header-one-ladning"
									src="/assets/images/iPhone 13 Pro Graphite Mockup label.png"
									alt=""
								/>
						</div>
							
						</div>
					</div>
				</div>
			</header>
			<section className="core-principal key-features">
				<div className="container">
					<h2 className="core-principal-h2">Key Features</h2>
					<div className="row">
						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>
									Buy & Sell <br /> Crypto
								</h2>
								<p>
									Our Dex Trading platform is your go-to for hassle-free buying
									and selling of digital assets. You can execute various trading
									strategies — effortlessly.
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>
									Anti-MEV <br /> Protection
								</h2>
								<p>
									Protects its users from frontrunning and sandwich attacks on a
									wide range of Ethereum transactions.
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>
									Limit Order
									<br /> Protocol
								</h2>
								<p>
									Gives you full control of your entry and exit positions on
									your trade, thereby protecting you against unexpected slippage
									with high gas efficiency.
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>
									Easy-to-navigate <br />
									User Interface
								</h2>
								<p>
									The UI has been meticulously designed with ease of use in
									mind, especially for beginners in the crypto space.{" "}
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>Custom Buttons</h2>
								<p>
									For a quick buy, you can save up to six (6) token contracts.
									This will enable you to save time when you need to execute
									trades as swiftly as possible.{" "}
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>Featured Tokens</h2>
								<p>
									To attract new users to our platform, we'll add temporary
									quick buy buttons for these featured partner tokens.
									Highlighted tokens will have a 0% platform fee for buy orders.{" "}
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>Limit Order Bot</h2>
								<p>
									When trading low volume tokens, limit order bot will work in
									place of limit order protocol, ensuring your trade is fully
									executed at the pre-set price.{" "}
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="core-principal-div-2">
								<h2>Token Scanner</h2>
								<p>
									Partnered with the DeFi Team to intergrate their Smart Contract Scanner, allowing you to quickly audit a token contract for greater confidence in the token.
									
									{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="partners">
				<h2>DApp Partners</h2>
				<div className="row">
					<div className="col">
							<a href="https://cryptocart.cc/" target="_blank" rel="noreferrer">
								<img
									src="/assets/images/1Coingecko_listing__1_-removebg-preview 1.png"
									alt=""
									className="mx-auto d-block img-fluid"
								/>
							</a>
					</div>	

					<div className="col">
							<a href="https://www.paal.ai/" target="_blank" rel="noreferrer">
								<img
									src="/assets/images/self_logo.png.png"
									alt=""
									className="mx-auto d-block img-fluid"
								/>
							</a>
					</div>
					<div className="col">
							<a href="#" target="_blank" rel="noreferrer">
								<img
									src="/assets/images/paalaiio_logo.png.png"
									alt=""
									className="mx-auto d-block img-fluid"
								/>
							</a>
					</div>
					<div className="col">
							<a href="https://cryptocart.cc/" target="_blank" rel="noreferrer">
								<img
									src="/assets/images/plusonecoin_logo.svg.png"
									alt=""
									className="mx-auto d-block img-fluid"
								/>
							</a>
					</div>
				</div>
					
					
			</section>
			<Footer/>
		</>
	);
}