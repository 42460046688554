import React from 'react'
import "../Styles/deadshot_service.css";
import Header from '../components/header';
import Footer from "../components/footer";
export default function DeadshotService() {
  return (
  <>
       <header className='roadmap-header'>
          <div className="container">
            <div className="row">
              <div className="col-6">
              <div className="header-text" style={{ textAlign: 'left' }}>

                <h2> In<span className='header-span'>Fuxe</span></h2>
                <p>
                Your go-to solution where you can integrate features <br />  for your project. 
                </p>
                <button type="button" className="btn btn-success lading-button">Get Started</button>

                </div>
                
              </div>
              <div className="col-6">
              </div>
            </div>
          </div>
     </header>
     <section className='what-is-daas'>
        <div className="container">
            <h2>What In<span className='daas-span'>Fuxe?</span></h2>
            <p className='daas-p'>
            DeadShot-as-a-Service is a set of comprehensive APIs and
            easy-to-navigate UI to fast track your app development process,
            with instant features for your project. 
            </p>
        </div>
     </section>
     <section className='daas-line'>
      <div className="container">
        <div className="daas-lines">
        <div className="row">
        <div className="col-4">
            <img src="/assets/images/Ellipse 31.png" className='dass-img-one' alt="" srcset="" />
          </div>
          <div className="col-4">
            <img src="/assets/images/Ellipse 37.png" className='dass-img-two' alt="" srcset="" />
          </div>
          <div className="col-4">
            <img src="/assets/images/Ellipse 36.png" className='dass-img-three ' alt="" srcset="" />
          </div>
         
        </div>

        </div>
       
      </div>
     </section>
     <Footer/>
 </>
  )};