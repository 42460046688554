import React from 'react'
import "../Styles/getlynx.css";
import Footer from "../components/footer";
export default function getlynx() {
  return (
  <>
  <header className='getlynx'>
        <div className="container">
            <h2>Get <span className='getlynx-span-text'>$FUXE</span></h2>
            <div className="getlynx-p">
                <p>Stake for up to 25% APR in $LYNX, get weekly reward share in
ETH and get exclusive access to all our premium products.</p>
            </div>
            <div className="get-debit-button">
                  <div className="row">
                  <div className="col-6">
                    <button type="button" className="btn btn-success lading-button">DeadShot</button>
                    </div>
                    <div className="col-6">
                    <button type="button" className="btn btn-success lading-button" style={{background:'black',color:'white'}}>DeadShot</button>
                    </div>
                  
                </div>
                </div>
        </div>
        
  </header>
 
     <section className='getlynx-section'>
   
    </section>
    <Footer/>
  </>
  )}