import React from "react";
import "../Styles/dashboard.css"; // Import your custom CSS file
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import Dashboard_header from "../components/dashboard-headher";
import DashBoardHeader from "../components/SideBar";

// BasicLineChart component
import {
    LinePlot,
    markElementClasses,
  } from '@mui/x-charts/LineChart';
  
    const pData1 = [2, 5.5, 2, 8.5, 1.5, 5];
    const pData2 = [6, 10, 2, 5, 2, 10];
    const pData3 = [5, 4.9, 4, 3.5, 1.5, 15];
    const xData = [1, 2, 3, 5, 8, 20];
    
function BasicLineChart() {
    return (
      <ChartContainer
        width={'620'}
        height={400}
        series={[
          { type: 'line', data: pData1, color: '#C0E6BA'},
          { type: 'line', data: pData2, color:'#04F280'},
          { type: 'line', data: pData3,color:"yellow" },
        ]}
        xAxis={[{ scaleType: 'point', data: xData, showLabels: false }]} // Hide x-axis labels
        yAxis={[{ showLabels: false }]} // Hide y-axis labels
        sx={{
        //   [`& .${lineElementClasses.root}`]: [
        //     { stroke: '#FF5733', strokeWidth: 2 }, // Change color of pData1
        //     { stroke: '#33FF57', strokeWidth: 2 }, // Change color of pData2
        //     { stroke: '#3357FF', strokeWidth: 2 }, // Change color of pData3
        //   ],
          [`& .${markElementClasses.root}`]: {
            display: 'none', // Hide dots on the line
          },
        }}
        disableAxisListener
      >
        <LinePlot />
      </ChartContainer>
    );
}
export default function Stock() {
	return (
		<>
            <div className="container-fluid">
                <div className="row">
                    <DashBoardHeader/>
                        <div className="col-md-10">
                            <Dashboard_header/>
                                {/* logo-price */}
                                    <div className="company-stock-heading">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h2 className="company-stock-h2"> <span><img src="/assets/images/pngegg (6) 1.png" alt="" srcset="" /></span>Apple inc. | AAPL
                                                </h2>
                                            </div>
                                            <div className="col-md-4">
                                            <h2 className="company-stock-h2">$213.39
                                            <span className="company-stock-span"><img src="/assets/images/Polygon 4.png" alt="" srcset="" />0.50%</span>
                                                </h2>
                                            </div>
                                        </div>                                   
                                    </div>
                                {/* End logo-price */}

                                {/* Market-volume */}
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div class="fux-dash-button-d"><div class="row"><div class="col-8"><p class="fux-data-p">Market Cap.</p><h3>$3,00,00</h3></div><div class="col-4"><div class="up align-middle"></div></div></div></div>
                                        </div>

                                        <div className="col-md-3">
                                            <div class="fux-dash-button-d"><div class="row"><div class="col-8"><p class="fux-data-p">Volime</p><h3>$3,00,00</h3></div><div class="col-4"><div class="up align-middle"></div></div></div></div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="buttons-stocks">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                P/E Ratio
                                                            </p>
                                                        </div>
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                P/E Growth
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                Dividend per share
                                                            </p>
                                                        </div>
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                Debt to equity Ratio
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                Gross profit margin
                                                            </p>
                                                        </div>
                                                        <div className="button-stock-button-g">
                                                            <p className="button-stock-button-g-p">
                                                                 Net profit margin
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/*End Market-volume */}

                                <div className="technical-model">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <p className="technical-model-p-a">
                                            Technical Analysis
                                            </p>
                                            <div class="technial-analysis-img">
                                                 <BasicLineChart />
                                            </div>
                                    <div className="model-pre-ps">
                                        <div className="row">
                                            <div className="col-6">
                                                 <p className="technial-analysis-p">Twitter News</p>
                                            </div> 
                                            <div className="col-12">
                                                <div className="twitter-news">
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-md-3">
                                    <p className="technical-model-p-a">
                                        Prediction Model
                                    </p>
                                    <div className="predication-model-stock">
                                        <div className="predication-model-stock-price">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 className="predication-model-stock-price-h6">Average Prediction</h6>
                                                    <h3 className="active predication-model-stock-price-h2">$200.88</h3>
                                                </div>
                                                <div className="col-4">
                                                    <p className="predication-model-stock-price-pp"><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none"><path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"></path></svg></span> 0.01%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="predication-model-stock-price">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 className="predication-model-stock-price-h6">RF Prediction</h6>
                                                    <h3 className="predication-model-stock-price-h2">$199.88</h3>
                                                </div>
                                                <div className="col-4">
                                                     <p className="predication-model-stock-price-pp"><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none"><path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"></path></svg></span> 0.01%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="predication-model-stock-price">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 className="predication-model-stock-price-h6">GB Prediction</h6>
                                                    <h3 className="predication-model-stock-price-h2">$200.25</h3>
                                                </div>
                                                <div className="col-4">
                                                    <p className="predication-model-stock-price-pp"><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none"><path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"></path></svg></span> 0.01%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="predication-model-stock-price">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 className="predication-model-stock-price-h6">LR Prediction</h6>
                                                    <h3 className="predication-model-stock-price-h2">$200.25</h3>
                                                </div>
                                                <div className="col-4">
                                                    <p className="predication-model-stock-price-pp"><span class="up-svg"><svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none"><path d="M3.95356 4.47628C3.71429 4.75255 3.28571 4.75255 3.04644 4.47628L0.46267 1.49279C0.126144 1.1042 0.402176 0.499999 0.916227 0.499999L6.08378 0.5C6.59783 0.5 6.87386 1.10421 6.53733 1.49279L3.95356 4.47628Z" fill="#FB0505"></path></svg></span> 0.01%</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="model-pre-ps">
                                            <div class="row">
                                                <div class="col-6">
                                                    <p class="technial-analysis-p">Other News</p></div>
                                                    <div class="col-12">
                                                    <div class="twitter-news">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="technical-model-p-a">
                                            <br />
                                        </p>
                                    <div className="twitter-sentiment">
                                        <h2 className="twitter-sentiment-h2">
                                            Twitter Sentiment
                                        </h2>
                                        <div className="twitter-sentiment-img-div">
                                            <img src="/assets/images/Group 23.png" alt="" className="mx-auto d-block Twitter-Sentiment-img" srcset="" />
                                            <div className="twitter-sentiment-img-arrow">
                                                <img src="/assets/images/line 25.png" alt="" className="twitter-sentiment-img-arrow-img" srcset="" />
                                                {/* <span className="twitter-sentiment-img-arrow-span">0</sp
                                                an> */}
                                            </div>
                                        </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="insider-trading">
                                                <h3 className="insider-trading-h3">
                                                    Insider Trading
                                                </h3>
                                            <div className="Senate-Trading-img">
                                                <img src="/assets/images/Vector 4.png" alt="" srcset="" className="mx-auto d-block" />
                                            </div>
                                            
                                        </div>
                                     </div>
                                    <div className="col-6">
                                        <div className="Senate-Trading">
                                            <h3 className="insider-trading-h3">
                                            Senate Trading
                                        </h3>
                                        <div className="Senate-Trading-img">
                                            <img src="/assets/images/Vector 4.png" alt="" srcset="" className="mx-auto d-block" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                    <div className="Institutional-Target-div">
                                        <h2 className="twitter-sentiment-h2">
                                            Institutional Target
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>  
            </div>
        </div>
        </>
    )}