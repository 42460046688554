import React, { useState } from 'react';
import "../Styles/token-utility.css"; // Import your custom CSS file
import Footer from "../components/footer_two";
export default function TokenUtility() {
    function SubscriptionAndLoyalty() {
        const [showSubscription, setShowSubscription] = useState(true);
        const [showLoyalty, setShowLoyalty] = useState(false);
        const [subscriptionButtonClass, setSubscriptionButtonClass] = useState("button-loy-one");
        const [loyaltyButtonClass, setLoyaltyButtonClass] = useState("button-loy-two");

        const handleSubscriptionClick = () => {
            setShowSubscription(true);
            setShowLoyalty(false);
            setSubscriptionButtonClass("button-loy-one");
            setLoyaltyButtonClass("button-loy-two");
        };
    
        const handleLoyaltyClick = () => {
            setShowSubscription(false);
            setShowLoyalty(true);
            setSubscriptionButtonClass("button-loy-two");
            setLoyaltyButtonClass("button-loy-one");
        };

        return (
            <>
                <section className="sub-loy">
                    <div className="container">
                        <div className="button-option">
                            <div className="row">
                                <div className={subscriptionButtonClass}>
                                    <p onClick={handleSubscriptionClick}>Subscription Model</p>
                                </div>
                                <div className={loyaltyButtonClass}>
                                    <p onClick={handleLoyaltyClick}>Loyalty Reward System</p>
                                </div>
                            </div>
                        </div>
                        {showSubscription && (
                            <div className="subcrition-box" id="subcrition">
                               <div className="lock-content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="lock-heading">
                            <h2>
                            Lock <span>$FUXE</span><br />
                            For unlimited <br />
                            Access.
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="lock-p">
                            <p>
                            Individual users can lock their $FUXE tokens to access FuxionHub’s Premium features.
                            </p>
                            <p>
                            Projects can  integrate FuxionHub API within their tech stack for instant features
                            </p>
                        </div>
                    </div>
                </div>
                <div className="inproject">
                    <div className="row">
                    <div className="col-md-6">
                            <div className="indivdual-box">
                                <img src="/assets/images/rRectangle 18.png" className='indivdual-img' alt="" srcset="" />
                                    <div className="indivdul-dontent">
                                        <div className="header-ind">
                                           <div className="head-img">
                                                <div className="row">
                                                    <div className="col-4">
                                                    <img src="/assets/images/1Logo Variations-06 1.png" className='h-imgss' alt="" srcset="" /> 
                                                    </div>
                                                    <div className="col-8">
                                                    <span className='ind-img-text'>Individual <br /> User</span>
                                                    </div>
                                                </div>
                                           </div>
                                           <div className="indivdul-p-t">
                                            <p>
                                            Individual users can lock their $FUXE tokens to access FuxionHub’s Premium features.
                                            </p>
                                           </div>
                                           <div className="indi-coiming">
                                            <p>
                                                Coming Soon
                                            </p>
                                           </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="indivdual-box">
                                <img src="/assets/images/Rectangle 18.png" className='indivdual-img' alt="" srcset="" />
                                    <div className="indivdul-dontent">
                                        <div className="header-ind">
                                           <div className="head-img">
                                                <div className="row">
                                                    <div className="col-4">
                                                    <img src="/assets/images/1Group 5.png" className='h-imgs' alt="" srcset="" /> 
                                                    </div>
                                                    <div className="col-8">
                                                    <span className='ind-img-text'>Project <br /> Integration</span>
                                                    </div>
                                                </div>
                                           </div>

                                           <div className="indivdul-p-t">
                                            <p>
                                            Projects can  integrate FuxionHub API within their tech stack for instant features
                                            </p>
                                           </div>
                                           <div className="indi-coiming">
                                            <p>
                                                Coming Soon
                                            </p>
                                           </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>
                        )}
                        {showLoyalty && (
                            <div className="Loyalty" id="Loyalty">
                                    <section className='loyalty-data-hold'>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="loyalty-data-hold-header">
                                                        <h2>Hold <span>$FUXE</span> <br />
                                                        For monthly <br />
                                                        Rewards.</h2> 
                                                    </div>
                                                  
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="loyalty-data-hold-p">
                                                        <p>
                                                        Hold $FUXE to earn passive income. 50% of project’s  monthly income will be distributed to loyal holders.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className='token-reward'>
                                        <div className="token-reward-heading">
                                            <h2>
                                                Reward Eligibility
                                            </h2>
                                        </div>
                                        <div className="token-reward-p">
                                            <p>
                                                Wallets holding a minimum of 1,000 $FUXE tokens at the time of the first snapshot qualify and must retain this requirement until the Second Snapshot. If the tokens go below this threshold at the second snapshot, he/she is disqualified and will not be rewarded.
                                            </p>
                                        </div>
                                            <div className="space-reward"></div>
                                        <div className="token-reward-heading">
                                            <h2>
                                                 How it works
                                            </h2>
                                        </div>
                                        <div className="token-reward-p">
                                            <p>
                                            The reward pool will be distributed proportionally based on the number of Qualified tokens held by each participant during the Second Snapshot.
                                            </p>
                                        </div>
                                    </section>
                                    <section className='reward-source'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="reward-source-img">
                                                    <img src="/assets/images/pngwing 2.png" alt="" srcset="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="reward-source-text">
                                                    <h2>Reward Source</h2>
                                                </div>
                                                <div className="reward-source-p">
                                                    <p>
                                                        <span><img src="/assets/images/roadmap button-2.png" alt="" srcset="" /></span> 50% of token Buy & Sell tax
                                                    </p>
                                                    <p>
                                                        <span><img src="/assets/images/roadmap button-2.png" alt="" srcset="" /></span> 50% of 1% crypto debit fee
                                                    </p>
                                                    <p>
                                                        <span><img src="/assets/images/roadmap button-2.png" alt="" srcset="" /></span> 50% of FuxionHub's 1% platform fee
                                                    </p>
                                                    <p>
                                                        <span><img src="/assets/images/roadmap button-2.png" alt="" srcset="" /></span> 50% of Web3.0 Escrow's 1% fee
                                                    </p>
                                                    <p>
                                                        <span><img src="/assets/images/roadmap button-2.png" alt="" srcset="" /></span> 100% Starbot Referral Bonus
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className='Scenarios'>
                                        <div className="Scenarios-heading">
                                            <h2>
                                                Scenarios
                                            </h2>
                                         </div>
                        <div className="row">
                         <div className="col-md-4">
                            <div className="indivdual-box-s">
                                <img src="/assets/images/rRectangle 18.png" className='indivdual-imge' alt="" srcset="" />
                                    <div className="indivdul-dontent">
                                        <div className="header-ind">
                                           <div className="head-img">
                                                <div className="row">
                                                    <div className="col-12">
                                                    <span className='ind-img-text'>Increasing <br />
                                                    Holdings</span>
                                                    </div>
                                                </div>
                                           </div>

                                           <div className="indivdul-p-t-t">
                                            <p>
                                            Snapshot 1 = 50K $FUXE
                                            </p>
                                            <p>
                                            Snapshot 2 = 75K $FUXE
                                            </p>
                                           </div>
                                            <div className="indivdul-con-t">
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p>
                                                            Qualified Tokens
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <button className='indivdul-button-s'>
                                                            50K $FUXE
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="indivdual-box-s">
                                <img src="/assets/images/rRectangle 18.png" className='indivdual-imge' alt="" srcset="" />
                                    <div className="indivdul-dontent">
                                        <div className="header-ind">
                                           <div className="head-img">
                                                <div className="row">
                                                    <div className="col-12">
                                                    <span className='ind-img-text'>Decreasing
                                                    Holdings</span>
                                                    </div>
                                                </div>
                                           </div>

                                           <div className="indivdul-p-t-t">
                                            <p>
                                            Snapshot 1 = 50K $FUXE
                                            </p>
                                            <p>
                                            Snapshot 2 = 25K $FUXE
                                            </p>
                                           </div>
                                            <div className="indivdul-con-t">
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p>
                                                            Qualified Tokens
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <button className='indivdul-button-s'>
                                                            25K $FUXE
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="indivdual-box-s box-s-p">
                                <img src="/assets/images/rRectangle 18.png" className='indivdual-imge' alt="" srcset="" />
                                    <div className="indivdul-dontent">
                                        <div className="header-ind">
                                           <div className="head-img">
                                                <div className="row">
                                                    <div className="col-12">
                                                    <span className='ind-img-text'>Static <br />
                                                    Holdings</span>
                                                    </div>
                                                </div>
                                           </div>

                                           <div className="indivdul-p-t-t">
                                            <p>
                                            Snapshot 1 = 50K $FUXE
                                            </p>
                                            <p>
                                            Snapshot 2 = 50K $FUXE
                                            </p>
                                           </div>
                                            <div className="indivdul-con-t">
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p>
                                                            Qualified Tokens
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <button className='indivdul-button-s'>
                                                            50K $FUXE
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        </div>
                         </section>
                        </div>
                        )}
                    </div>
                </section>
            </>
        );
    }
    return (
        <>
            <header className="header-token">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="token-heading-data">
                                <h2>Token <span>Utility</span></h2>
                            </div>
                            <div className="token-heading-p">
                                <p>
                                    Hold or Lock $FUXE for endless utilities and perks. This includes access to FuxionHub’s premium services (for individual users), development kit for instant features (project integration) and monthly rewards in ETH.
                                </p>
                            </div>
                            <div className="token-button">
                                <div className="row">
                                    <div className="col-3-d" style={{textAlign:'center'}}>
                                        <button className="btn btn-success lading-button-s card-details-lading-button">Learn More</button>
                                    </div>
                                    {/* <div className="col-3-d ">
                                        <div className="button-right-div">
                                            <button className="btn btn-border ">Get $FUXE</button>
                                        </div>
                                    </div>   */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="token-main-img">
                                <img src="/assets/images/Frame 162.png" alt="" className="mx-auto d-block" srcSet=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <SubscriptionAndLoyalty />
            <Footer/>
        </>
    );
}
