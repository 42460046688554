import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/dashboard.css"; // Import your custom CSS file


export default function DashboardHeader() {
  const [account, setAccount] = useState(null);

  return (
    <>
      {/* Header Part */}
      <header className="header-dashboard">
        <div className="row">
          <div className="col-md-8">
            <h2 className="headerklmklm-dashboard-h2">Hello <span className="span-header-h2">Cryus,</span></h2>
            <p className="header-dashboard-p">I’m <b>Pulse</b>, your personalized AI companion</p>
          </div>
          <div className="col-md-4">
            <div className="dashboard-sidebar-right">
              <div className="row">
                <div className="col-2">
                  <div className="notifaction">
                    <img src="/assets/images/Notification.svg" alt="" />
                  </div>
                </div>
                <div className="col-7">
                     <button className="dashboard-connect-wallet">
                      Connect Wallet
                    </button>
                </div>
                <div className="col-3">
                  <div>
                    <img src="/assets/images/IMG_2259 3.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Part */}
      <section className="market-model-search">
        <div className="row">
          <div className="col-md-4">
            <div className="dashboard-dropdown-m">
              <NavDropdown title="Market Type" id="navbarDropdown">
                <NavDropdown.Item as={Link} to="../Cryto">
                  Cryto
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="../stock">
                  Stocks
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="../forex">
                  Forex
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-dropdown-m">
              <NavDropdown title="AI Prediction Model" id="navbarDropdown">
                <NavDropdown.Item as={Link} to="../technical-analysis">
                  Technical Analysis
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="../sentimental-analysis">
                  Sentimental Analysis
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
          <div className="col-md-4">
            <div className="search-dashboard dashboard-search" style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <img src="/assets/images/material-symbols_search.svg" alt="" />
              </span>
              <input type="text" className="" value="search" style={{ marginLeft: '10px' }} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
