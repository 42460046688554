import React from "react";

import "../Styles/footer.css";

export default function footer() {
	return (
		<>
			<footer className="footer">
				<div className="container">
					<div className="footer-want">
						<div className="row">
							<div className="col-8">
								<h2>
									Want to Partner with us?
									<br />
									Talk to us today
								</h2>
							</div>
							<div className="col-4">
								<a href="Contact-us">
									<button
										type="button"
										className="btn btn-success f-lading-button"
										style={{ background: "black", color: "white" }}
									>
										Contact Us
									</button>
								</a>
							</div>
						</div>
					</div>

					<div className="footer-menu">
              <div className="row">
              
                <div className="col-4">
                <h6 class="text-uppercase fw-bold mb-4">
                      Products
                    </h6>
                    <p>
                      <a href="about" class="text-reset">About Us</a>
                    </p>
                    <p>
                      <a href="nft-debit-card" class="text-reset">Fuxion Pay</a>
                    </p>
                    <p>
                      <a href="web3-escrow" class="text-reset">Web3 Escrow</a>
                    </p>
                    <p>
                      <a href="fuxionhub" class="text-reset">FuxionHub</a>
                    </p>
                </div>
                <div className="col-4">
                <h6 class="text-uppercase fw-bold mb-4">
                Documentation
                    </h6>
                    <p>
                      <a href="roadmap" class="text-reset">RoadMap</a>
                    </p>
                    <p>
                      <a target="_blank" href="assets/pdf/Fuxion%20Labs%20Pitch%20Deck%20V1_July2024.pdf" class="text-reset">Pitch Deck</a>
                    </p>
                    <p>
                      <a href="Contact-us" class="text-reset">Contact Us</a>
                    </p>
                 
                </div>
                <div className="col-4">
                <h6 class="text-uppercase fw-bold mb-4">
                      Products
                    </h6>
                    <div className="row">
                      <div className="col-10"><input type="text" value="e-mail" className='footer-input' name="" id="" /></div>
                      <div className="col-2"><input type="button" value="Submit" className='footer-input-button' /></div>
                    </div>
                </div>
                </div>
              </div>
				</div>
			</footer>
		</>
	);
}
