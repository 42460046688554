import React from "react";
import Footer from "../components/footer";
import "../Styles/about.css";
export default function About() {
	return (
		<>
			<header className="about-header">
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                  <h2 className="lading-h2">
				  	Get Familiar <br /> <span className="h-lading-p"> with Fuxion Labs</span>
                  </h2>
                  <p className="lading-p"><b>Fusion Labs</b> empowers you to conquer the exciting <br /> world of web3..</p>
				  <div className="div-button-about">
	                  <button type="button" className="btn btn-success lading-about-button">Let's go</button>
				  </div>
                </div>
                <div className="col-md-6 about-image-div">
                    <img src="/assets/images/Component 1.png" className="about_header_img" alt="" srcset="" />
                </div>
                </div>
            </div>
        </header>
			<section className="about-who-are">
				<div className="container">
					<h2>Who are we?</h2>
					<p>
					Fuxion Labs is a software development company, that focuses on equipping users with the tools to explore the dynamic world of Web3, by forging effortless connections between projects, services,
					and communities within the web3 ecosystem.
					</p>
				</div>
			</section>
			<section className="mission-ves">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="mission-div">
								{/* <img src="/assets/images/compass.png" className="mission-principal-img" alt="" srcset="" style={{filter: 'brightness(1)'}}/> */}
								<h2 className="mission-h2">
									Mission{" "}
									<span>
										<img src="/assets/images/Frame 61.png" alt="" srcset="" />
									</span>
								</h2>
								<p>
									To empower individuals to unlock the full potential of Web3 by
									bridging different projects, communities, and customer-centric
									innovation.
								</p>
							</div>
						</div>

						<div className="col-md-6">
							<div className="mission-div">
								{/* <img src="/assets/images/binoculars.png" className="mission-principal-img" alt="" srcSet=""style={{filter: 'brightness(1.1)'}}/> */}

								<h2 className="mission-h2">
									Vision{" "}
									<span>
										<img src="/assets/images/Vector 2.png" alt="" srcset="" />
									</span>
								</h2>
								<p>
									To shape the future of web3 interconnectivity by simplifying
									access, fostering collaboration, and accelerating adoption for
									all.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="core-principal">
				<div className="container">
					<h2 className="core-principal-h2">Core Principles</h2>
					<div className="row">

						<div className="col-md-3">
							<div className="core-principal-div">
								<div className="core-principal-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="30"
										height="28"
										viewBox="0 0 30 28"
										fill="none"
									>
										<circle cx="15" cy="7" r="7" fill="#04F280" />
										<path
											d="M25 27.8568C27.7614 27.8568 30.0779 25.5582 29.1743 22.9488C28.4384 20.8237 27.227 18.8707 25.6066 17.2502C22.7936 14.4372 18.9782 17.2503 15 17.2503C11.0218 17.2503 7.20645 14.4372 4.3934 17.2502C2.77298 18.8707 1.56159 20.8237 0.82568 22.9488C-0.077919 25.5582 2.23858 27.8568 5 27.8568L7 27.8568H15L23 27.8568L25 27.8568Z"
											fill="#04F280"
										/>
									</svg>
								</div>
								<h2>User-Centric Design</h2>
								<p>
									We prioritize intuitive solutions that will build your
									confidence in navigating Web3
								</p>
							</div>
						</div>

						<div className="col-md-3 col-xs-6">
							<div className="core-principal-div">
								<div className="core-principal-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="30"
										height="30"
										viewBox="0 0 30 30"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M14.7029 2.70288C16.2595 2.70288 17.5392 1.51733 17.6884 0C19.6496 0.396118 21.4722 1.17468 23.0749 2.25476C22.8378 2.68408 22.7029 3.17773 22.7029 3.70288C22.7029 5.35974 24.046 6.70288 25.7029 6.70288C26.228 6.70288 26.7217 6.56787 27.151 6.33081C28.231 7.93347 29.0097 9.7561 29.4058 11.7174C27.8884 11.8666 26.7029 13.1462 26.7029 14.7029C26.7029 16.2595 27.8884 17.5392 29.4058 17.6884C29.1012 19.1965 28.5703 20.6228 27.8502 21.9301C27.4967 21.7837 27.1093 21.7029 26.7029 21.7029C25.046 21.7029 23.7029 23.046 23.7029 24.7029C23.7029 25.3022 23.8787 25.8606 24.1815 26.3291C22.3325 27.8384 20.1167 28.9154 17.6884 29.4058C17.5392 27.8884 16.2595 26.7029 14.7029 26.7029C13.1463 26.7029 11.8666 27.8884 11.7174 29.4058C9.75616 29.0096 7.93359 28.2311 6.33087 27.151C6.56793 26.7217 6.70288 26.228 6.70288 25.7029C6.70288 24.046 5.35974 22.7029 3.70288 22.7029C3.17773 22.7029 2.68408 22.8379 2.25476 23.075C1.17474 21.4723 0.396057 19.6497 0 17.6884C1.5174 17.5392 2.70288 16.2595 2.70288 14.7029C2.70288 13.1462 1.5174 11.8666 0 11.7174C0.339478 10.0364 0.960083 8.45703 1.81061 7.03088C2.32684 7.45105 2.98541 7.70288 3.70288 7.70288C5.35974 7.70288 6.70288 6.35974 6.70288 4.70288C6.70288 3.8833 6.37421 3.14038 5.84143 2.59888C7.55859 1.3396 9.55359 0.437012 11.7174 0C11.8666 1.51733 13.1463 2.70288 14.7029 2.70288ZM14.7229 20.1311C17.4843 20.1311 19.7229 17.8926 19.7229 15.1311C19.7229 12.3696 17.4843 10.1311 14.7229 10.1311C11.9615 10.1311 9.7229 12.3696 9.7229 15.1311C9.7229 17.8926 11.9615 20.1311 14.7229 20.1311Z"
											fill="#04F280"
										/>
									</svg>
								</div>
								<h2>Constant Innovation</h2>
								<p>
									We develop <br /> cutting-edge solutions that will unlock new
									possibilities in Web3
								</p>
							</div>
						</div>

						<div className="col-md-3 col-xs-6">
							<div className="core-principal-div">
								<div className="core-principal-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="30"
										viewBox="0 0 25 30"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M11.2642 0.23725L1.63668 4.51462C0.646419 4.95473 0 5.94499 0 7.03153V13.4957C0 21.129 5.28138 28.2671 12.3782 30C19.4751 28.2671 24.7565 21.129 24.7565 13.4957V7.03153C24.7565 5.94499 24.1101 4.95473 23.1198 4.51462L13.4923 0.23725C13.1416 0.0790831 12.7599 0 12.3782 0C11.9966 0 11.6149 0.0790833 11.2642 0.23725ZM19.2154 11.7885C19.8012 11.2027 19.8012 10.2529 19.2154 9.66715C18.6296 9.08136 17.6799 9.08136 17.0941 9.66715L10.3152 16.446L7.66239 13.7932C7.0766 13.2074 6.12685 13.2074 5.54107 13.7932C4.95528 14.379 4.95528 15.3288 5.54107 15.9145L9.25454 19.628L10.3152 20.6887L11.3759 19.628L19.2154 11.7885Z"
											fill="#04F280"
										/>
									</svg>
								</div>
								<h2>Security you can trust</h2>
								<p>
									Robust security measures ensure a safe and reliable experience
									for you and your assets
								</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="core-principal-div">
								<div className="core-principal-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="29"
										height="22"
										viewBox="0 0 29 22"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M9.5 7C11.433 7 13 5.433 13 3.5C13 1.567 11.433 0 9.5 0C7.567 0 6 1.567 6 3.5C6 5.433 7.567 7 9.5 7ZM19.5 9C21.433 9 23 7.433 23 5.5C23 3.567 21.433 2 19.5 2C17.567 2 16 3.567 16 5.5C16 7.433 17.567 9 19.5 9ZM13.373 11.8975C13.373 10.3617 12.2133 9.05192 10.722 8.68493C10.5904 8.65255 10.4593 8.61995 10.3288 8.58748C7.86955 7.97589 5.60407 7.41247 3.76634 9.2502C2.14591 10.8706 0.934523 12.8236 0.198618 14.9488C-0.704981 17.5582 1.61151 19.8568 4.37294 19.8568L6.37294 19.8568H10.373C12.0299 19.8568 13.373 18.5137 13.373 16.8568V11.8975ZM18 21.8568C16.3432 21.8568 15 20.5137 15 18.8568V13.8975C15 12.3617 16.1598 11.0519 17.651 10.6849C17.7823 10.6526 17.913 10.6201 18.0433 10.5877L18.0441 10.5875C20.5033 9.97592 22.7688 9.41251 24.6065 11.2502C26.2269 12.8707 27.4383 14.8237 28.1742 16.9488C29.0778 19.5582 26.7613 21.8568 23.9999 21.8568L21.9999 21.8568L18 21.8568Z"
											fill="#04F280"
										/>
									</svg>
								</div>
								<h2>Synergistic Collaboration</h2>
								<p>
									Unlock web3 potential through partnerships to build a
									connected ecosystem
								</p>
							</div>
						</div>

					</div>
				</div>
			</section>
			<Footer/>
		</>
	);
}
